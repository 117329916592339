import StationSpinner from 'components/station/common/Spinner';
import dayjs from 'dayjs';
import { swalToastError } from 'helpers/component-utils';
import {
  isCriticalCustomer,
  isNewCustomer,
  isNewOrCriticalCustomer
} from 'helpers/utils';
import { React, useEffect, useReducer, useState } from 'react';
import { Badge, Button, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import {
  getCompositeDoorJobs,
  getCuttingItems,
  getRemakeJobs
} from 'services/cuttingService';
import { FaChevronDown, FaChevronLeft } from 'react-icons/fa';

const initColours = [
  { value: 'Agate Grey', label: 'Agate Grey' },
  { value: 'Anteak', label: 'Anteak' },
  { value: 'Anthracite Grey', label: 'Anthracite Grey' },
  { value: 'Anthracite Grey Smooth', label: 'Anthracite Grey Smooth' },
  { value: 'Basalt Grey Smooth', label: 'Basalt Grey Smooth' },
  { value: 'Black Brown', label: 'Black Brown' },
  { value: 'Black Ulti-Matt', label: 'Black Ulti-Matt' },
  { value: 'Bronze Platin', label: 'Bronze Platin' },
  { value: 'Buckingham Grey', label: 'Buckingham Grey' },
  { value: 'Chartwell Green', label: 'Chartwell Green' },
  { value: 'Claystone', label: 'Claystone' },
  { value: 'Cream', label: 'Cream' },
  { value: 'Dark Green', label: 'Dark Green' },
  { value: 'Dark Red', label: 'Dark Red' },
  { value: 'Flemish Gold Smooth', label: 'Flemish Gold Smooth' },
  { value: 'Hazy Grey Finesse', label: 'Hazy Grey Finesse' },
  { value: 'Honey Oak Super Matt', label: 'Honey Oak Super Matt' },
  { value: 'Irish Oak', label: 'Irish Oak' },
  { value: 'Kensington Grey', label: 'Kensington Grey' },
  { value: 'Light Oak', label: 'Light Oak' },
  { value: 'Pebble Grey ', label: 'Pebble Grey ' },
  { value: 'Pyrite', label: 'Pyrite' },
  { value: 'Rosewood', label: 'Rosewood' },
  { value: 'Sage Smooth', label: 'Sage Smooth' },
  { value: 'Silver D Smooth', label: 'Silver D Smooth' },
  { value: 'Slate Grey', label: 'Slate Grey' },
  { value: 'Slate Grey Finesse', label: 'Slate Grey Finesse' },
  { value: 'Steel Blue', label: 'Steel Blue' },
  { value: 'Swamp Oak', label: 'Swamp Oak' },
  { value: 'White Woodgrain', label: 'White Woodgrain' },
  { value: 'Windsor', label: 'Windsor' }
];

const initOrderSubGroup = ['C085', 'C110', 'C180', 'C225', 'C150', 'none'];

var jobsLocked = [];

export default function CuttingListWidget(props) {
  const [date, setDate] = useState(new Date());
  const [isShowNotCut, setIsShowNotCut] = useState(false);
  const [cuttingsNotGroup, setCuttingsNotGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deliveryRouteGrouped, setDeliveryRouteGrouped] = useState([]);
  const [titleJobList, setTitleJobList] = useState('default');
  const [reloadData, setReloadData] = useState();
  const [extendRoute, setExtendRoute] = useState([]);
  const [extendMainName, setExtendMainName] = useState([]);
  const [extendOtherSubGroup, setExtendOtherSubGroup] = useState([]);
  const [extendWhiteSubGroup, setExtendWhiteSubGroup] = useState([]);
  const [summaryCut, setSummaryCut] = useState(0);
  const [summaryTotal, setSummaryTotal] = useState(0);
  const [colours, setColours] = useState(null);
  const [payloadColours, setPayloadColours] = useState('');
  const [channel, setChannel] = useState();
  const [groupType, setGroupType] = useState();
  const [colourOptions, setColourOptions] = useState(initColours);
  const [isOrderView, setIsOrderView] = useState(
    JSON.parse(localStorage.getItem('cutting-order-view')) || true
  );
  const [search, setSearch] = useState('');
  const [searchString, setSearchString] = useState('');
  const [deplaySearch, setDeplaySearch] = useState();

  const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    if (localStorage.getItem('cutting-order-view') == null) {
      localStorage.setItem('cutting-order-view', true);
    }

    const bc = new BroadcastChannel('lockJob');
    setChannel(bc);

    return () => {
      bc.close();
    };
  }, []);

  useEffect(() => {
    if (channel) {
      channel.onmessage = ev => {
        let jobNo = '';
        if (ev.data.message == 'lock') {
          jobNo = ev.data?.jobNo;
          jobsLocked = [...jobsLocked, jobNo];
        } else {
          jobNo = ev.data?.jobNo;
          const remainLocked = jobsLocked.filter(job => job != jobNo);
          jobsLocked = remainLocked;
        }
        forceUpdate();
      };
    }
  }, [channel]);

  const getCurrentDate = () => {
    const currentDate = dayjs(date).format('YYYY-MM-DD');
    return currentDate;
  };

  const loadDefaultJobs = currentDate => {
    setIsLoading(true);

    const isOrderViewFromStorage = JSON.parse(
      localStorage.getItem('cutting-order-view')
    );
    getCuttingItems(currentDate, payloadColours, isOrderViewFromStorage, search)
      .then(res => {
        setIsLoading(false);
        if (res.data && res.data.cuttingItems.length > 0) {
          let data = res.data.cuttingItems.filter(item => item.total > 0);

          getSummaryCutAndTotal(data);

          //sort remake
          data = data.sort((a, b) => {
            if (a.jobNo.length > 8) {
              return -1;
            }
          });

          //sort is new and critical customer
          data = data.sort((a, b) => {
            if (isNewOrCriticalCustomer(a.priorityType)) {
              return -1;
            }
          });

          if (isShowNotCut) {
            data = data.filter(c => c.cut < c.total);
          }

          if (isOrderView) {
            if (res.data.allColours.length > 0) {
              setColourOptions(
                res.data.allColours.map(item => {
                  return {
                    value: item,
                    label: item
                  };
                })
              );
            } else {
              setColourOptions([]);
            }
            const groupByGroupType = Object.groupBy(
              data,
              ({ groupType }) => groupType
            );
            setDeliveryRouteGrouped(groupByGroupType);
          } else {
            const groupByDeliveryRoute = Object.groupBy(
              data,
              ({ deliveryRoute }) => deliveryRoute
            );
            setDeliveryRouteGrouped(groupByDeliveryRoute);

            setCuttingsNotGroup(data);
          }
        } else {
          setDeliveryRouteGrouped([]);
          setCuttingsNotGroup([]);
          setColourOptions([]);
        }
      })
      .catch(error => {
        swalToastError(`Get Cutting Details Failed`);
      });
  };

  const getSummaryCutAndTotal = data => {
    setSummaryCut(
      data.reduce(
        (accumulator, currentValue) => accumulator + currentValue.cut,
        0
      )
    );

    setSummaryTotal(
      data.reduce(
        (accumulator, currentValue) => accumulator + currentValue.total,
        0
      )
    );
  };

  const loadCompositeDoorJobs = currentDate => {
    setIsLoading(true);

    getCompositeDoorJobs(currentDate, payloadColours, search)
      .then(res => {
        setIsLoading(false);
        if (res.data && res.data.length > 0) {
          const data = res.data.filter(item => item.total > 0);

          getSummaryCutAndTotal(data);

          const dataSortedByRefixJob = data.sort((a, b) => {
            if (a.jobNo.length > 8) {
              return -1;
            }
          });

          const dataSortedByCustomerType = dataSortedByRefixJob.sort((a, b) => {
            if (isNewOrCriticalCustomer(a.priorityType)) {
              return -1;
            }
          });

          const groupByDeliveryRoute = Object.groupBy(
            dataSortedByCustomerType,
            ({ deliveryRoute }) => deliveryRoute
          );
          setDeliveryRouteGrouped(groupByDeliveryRoute);
          setCuttingsNotGroup(dataSortedByCustomerType);
        } else {
          setDeliveryRouteGrouped([]);
          setCuttingsNotGroup([]);
        }
      })
      .catch(error => {
        swalToastError(`Get Cutting Details Failed`);
      });
  };

  const loadRemakeJobs = currentDate => {
    setIsLoading(true);

    getRemakeJobs(currentDate, payloadColours, search)
      .then(res => {
        setIsLoading(false);
        if (res.data && res.data.length > 0) {
          const data = res.data.filter(item => item.total > 0);

          getSummaryCutAndTotal(data);

          const dataSortedByRefixJob = data.sort((a, b) => {
            if (a.jobNo.length > 8) {
              return -1;
            }
          });

          const dataSortedByCustomerType = dataSortedByRefixJob.sort((a, b) => {
            if (isNewOrCriticalCustomer(a.priorityType)) {
              return -1;
            }
          });

          const groupByDeliveryRoute = Object.groupBy(
            dataSortedByCustomerType,
            ({ deliveryRoute }) => deliveryRoute
          );
          setDeliveryRouteGrouped(groupByDeliveryRoute);
          setCuttingsNotGroup(dataSortedByCustomerType);
        } else {
          setDeliveryRouteGrouped([]);
          setCuttingsNotGroup([]);
        }
      })
      .catch(error => {
        swalToastError(`Get Cutting Details Failed`);
      });
  };

  useEffect(() => {
    const currentDate = getCurrentDate();
    setTitleJobList('default');
    loadDefaultJobs(currentDate);
  }, [isShowNotCut]);

  useEffect(() => {
    clearInterval(reloadData);
    autoRefreshJobs();
    return () => {
      clearInterval(reloadData);
    };
  }, [titleJobList, date, payloadColours, isOrderView, search]);

  const autoRefreshJobs = () => {
    const currentDate = getCurrentDate();
    if (isOrderView) {
      loadDefaultJobs(currentDate);
      setReloadData(
        setInterval(() => {
          loadDefaultJobs(currentDate);
        }, 5 * 60 * 1000)
      );
    } else {
      if (titleJobList === 'remake') {
        loadRemakeJobs(currentDate);
        setReloadData(
          setInterval(() => {
            loadRemakeJobs(currentDate);
          }, 5 * 60 * 1000)
        );
      } else if (titleJobList === 'composite') {
        loadCompositeDoorJobs(currentDate);
        setReloadData(
          setInterval(() => {
            loadCompositeDoorJobs(currentDate);
          }, 5 * 60 * 1000)
        );
      } else {
        loadDefaultJobs(currentDate, false);
        setReloadData(
          setInterval(() => {
            loadDefaultJobs(currentDate);
          }, 5 * 60 * 1000)
        );
      }
    }
  };

  const handleChangeViewCut = () => {
    setIsShowNotCut(!isShowNotCut);
  };

  const handleOpenCuttingDetail = item => {
    channel.postMessage({ message: 'lock', jobNo: item.jobNo });
    props.setCurrentCuttingDetail(item);
    props.setIsShowCuttingDetail(true);
    props.setTitleCuttingList(titleJobList);
  };

  const handleCollapseMainName = mainName => {
    const element = document.getElementById(`collapseMainName${mainName}`);
    if (element) {
      if (element.classList.value.includes('show')) {
        element.classList.remove('show');
        setExtendMainName(extendMainName.filter(item => item != mainName));
      } else {
        element.classList.add('show');
        setExtendMainName([...extendMainName, mainName]);
      }
      console.log(extendMainName);
    }
  };

  const handleCollapseSubGroup = (group, subGroup) => {
    const element = document.getElementById(`collapse${group + subGroup}`);
    if (element) {
      if (element.classList.value.includes('show')) {
        element.classList.remove('show');
        if (group === 'white') {
          setExtendWhiteSubGroup(
            extendWhiteSubGroup.filter(item => item != subGroup)
          );
        } else if (group === 'other') {
          setExtendOtherSubGroup(
            extendOtherSubGroup.filter(item => item != subGroup)
          );
        }
      } else {
        element.classList.add('show');
        if (group === 'white') {
          setExtendWhiteSubGroup([...extendWhiteSubGroup, subGroup]);
        } else if (group === 'other') {
          setExtendOtherSubGroup([...extendOtherSubGroup, subGroup]);
        }
      }
    }
  };

  const handleCollapseDeliveryRoute = (route, idx) => {
    const element = document.getElementById(`collapseDeliveryRoute${idx}`);
    if (element) {
      if (element.classList.value.includes('show')) {
        element.classList.remove('show');
        setExtendRoute(extendRoute.filter(item => item != route));
      } else {
        element.classList.add('show');
        setExtendRoute([...extendRoute, route]);
      }
    }
  };

  const generateColor = job => {
    if (jobsLocked.includes(job.jobNo)) {
      return '#748194';
    } else {
      return job.cut >= job.total ? '#a7f2ca' : '';
    }
  };

  const isDisabledJob = jobNo => {
    if (jobsLocked.includes(jobNo)) {
      return true;
    } else {
      return false;
    }
  };

  const generateAncillItem = jobs => {
    if (jobs.length > 0) {
      return jobs.map(
        (record, ind) =>
          record.total > 0 && (
            <div
              key={ind}
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                alignItems: 'center',
                padding: '5px 60px',
                backgroundColor: generateColor(record)
              }}
            >
              <div>{record.jobNo}</div>
              <div>{record.cut}</div>
              <div>{record.total}</div>
              <div>
                <Button
                  style={{
                    backgroundColor: generateColor(record)
                  }}
                  variant="falcon-default"
                  onClick={() => handleOpenCuttingDetail(record)}
                  disabled={isDisabledJob(record.jobNo)}
                >
                  Detail
                </Button>
              </div>
            </div>
          )
      );
    }
  };

  const newOrCriticalCustomer = priorityType => {
    if (isCriticalCustomer(priorityType)) {
      return (
        <Badge pill bg="warning" text="dark" className="me-2">
          {priorityType}
        </Badge>
      );
    } else if (isNewCustomer(priorityType)) {
      return (
        <Badge pill bg="info" className="me-2">
          {priorityType}
        </Badge>
      );
    }
  };

  const isAllJobsAsCut = jobs => {
    const jobsCut = jobs.filter(j => j.cut >= j.total);
    if (jobsCut.length === jobs.length) {
      return true;
    } else {
      return false;
    }
  };

  const isExtendedMainName = mainName => {
    return extendMainName.includes(mainName);
  };

  const isExtendedSubGroup = (group, subGroup) => {
    return group === 'white'
      ? extendWhiteSubGroup.includes(subGroup)
      : extendOtherSubGroup.includes(subGroup);
  };

  const isExtendedDeliveryRoute = route => {
    return extendRoute.includes(route);
  };

  const generateSubGroup = (group, deliveryRouteItems) => {
    const subGroupItems = Object.groupBy(
      deliveryRouteItems,
      ({ subGroup }) => subGroup
    );

    return initOrderSubGroup.map((subGroup, index) =>
      subGroup != 'none'
        ? subGroupItems[subGroup] &&
          subGroupItems[subGroup].length > 0 && (
            <div key={index}>
              <div
                className="fst-italic gap-2"
                role="button"
                onClick={() => handleCollapseSubGroup(group, subGroup)}
                style={{
                  fontWeight: 500,
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                  padding: '10px 20px',
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr 1fr'
                }}
              >
                <div>
                  {subGroup}{' '}
                  {group === 'other' ? (
                    extendOtherSubGroup.includes(subGroup) ? (
                      <FaChevronDown />
                    ) : (
                      <FaChevronLeft />
                    )
                  ) : (
                    ''
                  )}
                  {group === 'white' ? (
                    extendWhiteSubGroup.includes(subGroup) ? (
                      <FaChevronDown />
                    ) : (
                      <FaChevronLeft />
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div style={{ fontWeight: 600 }}>
                  Cut: {calculateRouteCut(subGroupItems[subGroup])}
                </div>
                <div style={{ fontWeight: 600 }}>
                  Total: {calculateRouteTotal(subGroupItems[subGroup])}
                </div>
                <div style={{ fontWeight: 600 }}></div>
                <div style={{ fontWeight: 600 }}></div>
              </div>
              <div
                className={`collapse multi-collapse ${
                  isExtendedSubGroup(group, subGroup) ? 'show' : ''
                }`}
                id={'collapse' + group + subGroup}
              >
                {generateMainNameGrouped(subGroupItems[subGroup])}
              </div>
            </div>
          )
        : subGroupItems['none'] &&
          subGroupItems['none'].length > 0 && (
            <div key={'none'}>
              <div
                className="fst-itali gap-2"
                role="button"
                onClick={() => handleCollapseSubGroup(group, 'none')}
                style={{
                  fontWeight: 500,
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                  padding: '10px 20px',
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr 1fr'
                }}
              >
                <div>
                  {'Others'}{' '}
                  {group === 'other' ? (
                    extendOtherSubGroup.includes('none') ? (
                      <FaChevronDown />
                    ) : (
                      <FaChevronLeft />
                    )
                  ) : (
                    ''
                  )}
                  {group === 'white' ? (
                    extendWhiteSubGroup.includes('none') ? (
                      <FaChevronDown />
                    ) : (
                      <FaChevronLeft />
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div style={{ fontWeight: 600 }}>
                  Cut: {calculateRouteCut(subGroupItems['none'])}
                </div>
                <div style={{ fontWeight: 600 }}>
                  Total: {calculateRouteTotal(subGroupItems['none'])}
                </div>
                <div style={{ fontWeight: 600 }}></div>
                <div style={{ fontWeight: 600 }}></div>
              </div>
              <div
                className={`collapse multi-collapse ${
                  isExtendedSubGroup(group, 'none') ? 'show' : ''
                }`}
                id={'collapse' + group + 'none'}
              >
                {generateMainNameGrouped(subGroupItems['none'])}
              </div>
            </div>
          )
    );
  };

  const generateMainNameGrouped = deliveryRoute => {
    const groupedByMainName = Object.groupBy(
      deliveryRoute,
      ({ mainName }) => mainName
    );
    if (groupedByMainName && Object.keys(groupedByMainName).length > 0) {
      return Object.keys(groupedByMainName).map((mainName, index) => (
        <div key={index}>
          <div
            className="fst-italic d-flex align-items-center gap-2"
            role="button"
            onClick={() => handleCollapseMainName(mainName)}
            style={{
              fontWeight: 500,
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              padding: '10px 40px',
              backgroundColor: isAllJobsAsCut(groupedByMainName[mainName])
                ? '#a7f2ca'
                : ''
            }}
          >
            <div>
              {mainName}
              {'  '}
              {extendMainName.includes(mainName) ? (
                <FaChevronDown />
              ) : (
                <FaChevronLeft />
              )}
            </div>
            <div>
              {newOrCriticalCustomer(
                groupedByMainName[mainName][0].priorityType
              )}
            </div>
          </div>
          <div
            className={`collapse multi-collapse ${
              isExtendedMainName(mainName) ? 'show' : ''
            }`}
            id={'collapseMainName' + mainName}
            style={{
              backgroundColor: isAllJobsAsCut(groupedByMainName[mainName])
                ? '#a7f2ca'
                : ''
            }}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                marginBottom: '10px',
                padding: '0 60px'
              }}
            >
              <div style={{ fontWeight: 500 }}>Job No.</div>
              <div style={{ fontWeight: 500 }}>Cut</div>
              <div style={{ fontWeight: 500 }}>Total</div>
              <div style={{ fontWeight: 500 }}></div>
            </div>

            {groupedByMainName[mainName].length > 0 &&
              generateAncillItem(groupedByMainName[mainName])}
          </div>
        </div>
      ));
    }
  };

  const handleChangeCompositeDoorJob = () => {
    setTitleJobList('composite');
  };

  const handleChangeRemakeJob = () => {
    setTitleJobList('remake');
  };

  const calculateRouteCut = deliveryRouteGrouped => {
    return deliveryRouteGrouped.reduce(
      (accumulator, currentValue) => accumulator + currentValue.cut,
      0
    );
  };

  const calculateRouteTotal = deliveryRouteGrouped => {
    return deliveryRouteGrouped.reduce(
      (accumulator, currentValue) => accumulator + currentValue.total,
      0
    );
  };

  const onChangeColours = colours => {
    setColours(colours);
    let payloadColours = '';
    if (colours.length > 0) {
      payloadColours = colours.map(item => item.value).join(',');
    }
    setPayloadColours(payloadColours);
  };

  const handleChangeView = e => {
    localStorage.setItem('cutting-order-view', e.target.checked);
    setIsOrderView(e.target.checked);

    //reset search
    setSearch('');
  };

  const generateGroup = item => {
    let title = '';
    switch (item) {
      case 'n&c':
        title = 'New And Critical';
        break;
      case 'ccf':
      case 'odr':
      case 'remake':
        title = 'CCF/ODR/Remake';
        break;
      case 'compdoor':
        title = 'Comp Doors';
        break;
      case 'routes':
        title = 'Jobs with routed Add-on/Head vent';
        break;
      case 'catd':
        title = 'Cat D Colours';
        break;
      case 'other':
        title = 'Other Colours';
        break;
      case 'white':
        title = 'White Colours';
        break;
      case 'others':
        title = 'Others';
        break;
      default:
        title = item;
        break;
    }
    return title;
  };

  const generateDeliveryRouteView = () => {
    return Object.keys(deliveryRouteGrouped).length > 0 ? (
      Object.keys(deliveryRouteGrouped).map((item, idx) => (
        <div key={idx}>
          <div
            className="fst-italic fw-bold"
            role="button"
            onClick={() => handleCollapseDeliveryRoute(item, idx)}
            style={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              paddingTop: '10px',
              paddingBottom: '10px',
              backgroundColor: isAllJobsAsCut(deliveryRouteGrouped[item])
                ? '#a7f2ca'
                : ''
            }}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr'
              }}
            >
              <div>
                {generateGroup(item)}
                {'  '}
                {extendRoute.includes(item) ? (
                  <FaChevronDown />
                ) : (
                  <FaChevronLeft />
                )}
              </div>
              <div style={{ fontWeight: 600 }}>
                Cut: {calculateRouteCut(deliveryRouteGrouped[item])}
              </div>
              <div style={{ fontWeight: 600 }}>
                Total: {calculateRouteTotal(deliveryRouteGrouped[item])}
              </div>
              <div style={{ fontWeight: 600 }}></div>
              <div style={{ fontWeight: 600 }}></div>
            </div>
          </div>
          <div
            className={`collapse multi-collapse ${
              isExtendedDeliveryRoute(item) ? 'show' : ''
            }`}
            id={'collapseDeliveryRoute' + idx}
          >
            {isOrderView && (item === 'other' || item === 'white')
              ? generateSubGroup(item, deliveryRouteGrouped[item])
              : generateMainNameGrouped(deliveryRouteGrouped[item])}
          </div>
        </div>
      ))
    ) : (
      <div>
        <div className="text-center">No Record Found</div>
      </div>
    );
  };

  const handleChangeSearch = e => {
    const value = e.target.value;
    setSearchString(value);

    clearTimeout(deplaySearch);

    setDeplaySearch(
      setTimeout(() => {
        setSearch(value);
      }, 2000)
    );
  };

  return (
    <>
      <div className="overflow-hidden" style={{ minHeight: 300 }}>
        <div className="d-flex justify-content-between align-items-center px-2 my-2">
          <div className="d-flex align-items-center gap-4">
            <DatePicker
              selected={date}
              onChange={date => setDate(date)}
              dateFormat="dd/MM/yyyy"
              className="form-control"
              placeholderText="Select Date"
            />

            <Form.Check
              type="switch"
              id="isShowRoute"
              label="Order View"
              onChange={handleChangeView}
              defaultChecked={JSON.parse(
                localStorage.getItem('cutting-order-view')
              )}
            />
          </div>

          <div className="d-flex align-items-center">
            {!isOrderView && (
              <Button
                size="sx"
                variant="falcon-default"
                onClick={handleChangeCompositeDoorJob}
              >
                {'Composite Door Jobs'}
              </Button>
            )}
            {!isOrderView && (
              <Button
                size="sx"
                variant="falcon-default"
                onClick={handleChangeRemakeJob}
              >
                {'Remake Jobs'}
              </Button>
            )}

            <Button
              size="sx"
              style={
                isOrderView ? { minWidth: '200px' } : { minWidth: '150px' }
              }
              variant="falcon-default"
              onClick={handleChangeViewCut}
            >
              {isShowNotCut ? 'View All Cut Ancills' : 'View Not Cut Ancills'}
            </Button>

            <Form.Control
              style={{ minWidth: '250px' }}
              id={'cutting-search'}
              placeholder={'Search By Route, Customer, Job Number'}
              value={searchString}
              name="search"
              onChange={handleChangeSearch}
              type="text"
            />
            <Select
              closeMenuOnSelect={false}
              options={colourOptions}
              placeholder="Select Colours"
              isMulti
              classNamePrefix="react-select"
              value={colours}
              onChange={value => onChangeColours(value)}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minWidth: '250px'
                })
              }}
              maxMenuHeight={200}
            />
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr'
          }}
        >
          <div style={{ fontWeight: 600 }}>Summary</div>
          <div style={{ fontWeight: 600 }}>Cut: {summaryCut}</div>
          <div style={{ fontWeight: 600 }}>Total: {summaryTotal}</div>
          <div style={{ fontWeight: 600 }}></div>
          <div style={{ fontWeight: 600 }}></div>
        </div>
        <Row>{generateDeliveryRouteView()}</Row>
      </div>
    </>
  );
}
