import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { GlobalHotKeys, configure } from 'react-hotkeys';
import { useDispatch, useSelector } from 'react-redux';
import {
  getListFaultArea,
  getStationScanningActions,
  getStationStages
} from 'redux/slices/stationSlice';
import { getStationLayout, updateStation } from 'services/stationService';

import { swalToastError } from 'helpers/component-utils';
import 'react-toastify/dist/ReactToastify.css';
import { getMachines } from 'services/machineService';
import { getProductAllocations } from 'services/qcService';

export default function StationFooter(props) {
  const dispatch = useDispatch();

  const loginData = props.loginData;

  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalSetting, setShowModalSetting] = useState(false);
  const [station, setStation] = useState({});
  const [newStation, setNewStation] = useState({
    id: 0,
    stationName: '',
    scanningActionId: 0,
    startStage: 0,
    completedStage: 0,
    scanNextToComplete: false,
    machineId: 0,
    faultAreaId: 0,
    productAllocationId: 0
  });
  const [machines, setMachines] = useState([]);
  const [stationMachine, setStationMachine] = useState(0);
  const [productsAlocation, setProductsAllocation] = useState([]);

  let stageOptions = [];
  let actionOptions = [];
  let machineOptions = [];
  let faultAreaOptions = [];

  const keyMap = {
    EV_STATION_SETTING: 'f2',
    EV_COMPLETE: 'f8'
  };

  configure({
    ignoreTags: ['input', 'select', 'textarea'],
    ignoreEventsCondition: function () {}
  });

  //hide popup when loggoed out
  useEffect(() => {
    if (!props.loginData.isLogged) {
      setShowModalSetting(false);
    }
  }, [props.loginData]);

  const permissions = useSelector(store => store.permission.permissions);

  useEffect(() => {
    dispatch(getStationStages());
    dispatch(getStationScanningActions());
    dispatch(getListFaultArea());

    getMachines()
      .then(res => {
        setMachines(res.machines);
      })
      .catch(res => {
        swalToastError('Get Machines Failed');
      });

    getProductAllocations()
      .then(res => {
        if (res && res.data) {
          setProductsAllocation(res.data);
        }
      })
      .catch(res => {
        swalToastError('Get Products Alocation Failed');
      });
  }, []);

  useEffect(() => {
    setStation(props.station);
    setNewStation({ ...props.station });
  }, [props.station]);

  const handleOpenPanelSetting = () => {
    props.openPanelSetting();
  };

  const handleOpenPrintLabels = () => {
    props.setIsShowPrintLabels(true);
  };

  const handleOpenStationSetting = () => {
    setShowModalSetting(true);
  };

  const handleCompleteAction = () => {
    if (!loginData.userID < 1) {
      swalToastError('Please login first!');
      return;
    }

    let isAppliedPanelExist =
      props.latestLayout.find(
        x =>
          x.panelId == 3 ||
          x.panelId == 5 ||
          x.panelId == 16 ||
          x.panelId == 23 ||
          x.panelId == 26 ||
          x.panelId == 33 ||
          x.panelId == 28 ||
          x.panelId == 30 ||
          x.panelId == 34 ||
          x.panelId == 35 ||
          x.panelId == 36 ||
          x.panelId == 41
      ) != undefined;

    if (!isAppliedPanelExist) {
      swalToastError('Panel Frame Detail or Frame Detail v2 is not existed!');
      return;
    }

    if (!props.isScanning) {
      switch (station.appliedPanelId) {
        case 3:
        case 16:
        case 23:
        case 26:
        case 33:
        case 34:
        case 35:
        case 36:
          swalToastError('Please start scanning first!');
          break;
        case 5:
          swalToastError('Please start batch first!');
          break;
      }
      return;
    }

    setShowModalConfirm(true);
  };

  const handleInputChange = e => {
    setNewStation({ ...newStation, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = e => {
    setNewStation({ ...newStation, [e.target.name]: e.target.checked });
  };

  const handleSubmitSetting = e => {
    e.preventDefault();
    e.stopPropagation();

    updateStation(newStation).then(res => {
      if (res?.success) {
        props.onStationUpdated(res.newPath);
      }
    });
  };

  const onConfirmComplete = () => {
    props.onComplete(0, true);
    setShowModalConfirm(false);
  };

  const keyMapHandlers = {
    EV_COMPLETE: event => {
      handleCompleteAction();
    },
    EV_STATION_SETTING: event => {
      if (loginData.permissionId != process.env.REACT_APP_ADMIN_PERMISSION)
        return;
      handleOpenStationSetting();
    }
  };

  const stages = useSelector(store => store.station.stages);
  const scanningActions = useSelector(store => store.station.actions);
  const faultAreas = useSelector(store => store.station.faultAreas);

  if (stages?.length > 0) {
    stages.forEach(function (item, index) {
      stageOptions.push(
        <option key={'stage' + index + 1} value={item.id}>
          {item.name}
        </option>
      );
    });
  } else {
    stageOptions.push(
      <option key={'stage0'} value={0}>
        No stage found.
      </option>
    );
  }

  if (scanningActions?.length > 0) {
    actionOptions.push(
      <option key={'action0'} value={0}>
        Please select an action
      </option>
    );
    scanningActions.forEach(function (item, index) {
      actionOptions.push(
        <option key={'action' + index + 1} value={item.id}>
          {item.actionName}
        </option>
      );
    });
  } else {
    actionOptions.push(
      <option key={'action0'} value={0}>
        No action found.
      </option>
    );
  }

  if (machines.length > 0) {
    machineOptions.push(
      <option key={'machine0'} value={0}>
        Please select a machine
      </option>
    );
    machines.map((m, i) => {
      machineOptions.push(
        <option key={'machine' + i + 1} value={m.machineID}>
          {m.machineName}
        </option>
      );
    });
  } else {
    machineOptions.push(
      <option key={'machineO'} value={0}>
        No machine found.
      </option>
    );
  }

  if (faultAreas.length > 0) {
    faultAreaOptions.push(
      <option key={'area0'} value={0}>
        Please select an area
      </option>
    );
    faultAreas.map((f, i) => {
      faultAreaOptions.push(
        <option key={'area' + i + 1} value={f.id}>
          {f.areaName}
        </option>
      );
    });
  } else {
    faultAreaOptions.push(
      <option key={'area0'} value={0}>
        No area found.
      </option>
    );
  }

  return (
    <>
      <footer className="footer">
        <nav className="navbar bg-light navbar-dark navbar-glass fs--1">
          <div className="container-fluid d-flex justify-content-between align-item-center p-0">
            <div>
              <div className="d-flex gap-1 footer-text">
                <span>Scan Next Item to Complete:</span>
                {station.scanNextToComplete ? (
                  <span style={{ color: 'green' }}>ENABLED</span>
                ) : (
                  <span style={{ color: 'red' }}>DISABLED</span>
                )}
              </div>
            </div>

            <div>
              {loginData.permissionId ==
              process.env.REACT_APP_ADMIN_PERMISSION ? (
                <span
                  className="footer-text"
                  onClick={handleOpenStationSetting}
                >
                  <b>F2: Station Setting</b>
                </span>
              ) : (
                ''
              )}
            </div>

            <div>
              {loginData.permissionId ==
              process.env.REACT_APP_ADMIN_PERMISSION ? (
                <span className="footer-text" onClick={handleOpenPanelSetting}>
                  <b>F4: Available Widgets</b>
                </span>
              ) : (
                <span className="footer-text" onClick={handleOpenPrintLabels}>
                  <b>F4: Print Frame/Sash Labels</b>
                </span>
              )}
            </div>

            <div>
              <span className="footer-text" onClick={handleCompleteAction}>
                <b>F8: {station.scanningActionName}</b>
              </span>
            </div>
          </div>
        </nav>
        <Modal
          show={showModalConfirm}
          onHide={e => {
            console.log();
          }}
          dialogClassName="modal-60w"
          size="xl"
          animation={false}
          autoFocus={false}
          style={{ fontSize: '15px' }}
        >
          <Modal.Header>
            <Modal.Title>
              <h3>Confirm complete</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to {station.scanningActionName}?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="button" onClick={onConfirmComplete}>
              Yes
            </Button>{' '}
            <Button
              variant="secondary"
              onClick={() => {
                setShowModalConfirm(false);
              }}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showModalSetting}
          onHide={e => {
            console.log();
          }}
          dialogClassName="w-25 w-sm-50"
          animation={false}
          autoFocus={false}
          style={{ fontSize: '15px' }}
        >
          <Modal.Header>
            <Modal.Title>
              <h3>Station Setting</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form autoComplete="off" noValidate onSubmit={handleSubmitSetting}>
              <Form.Group className="mb-3" controlId="setup.stationName">
                <Form.Label>Station Name</Form.Label>
                <Form.Control
                  name="stationName"
                  type="text"
                  autoFocus={true}
                  required
                  value={newStation.stationName}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="setup.startStage">
                <Form.Label>Start Stage:</Form.Label>
                <Form.Control
                  as="select"
                  name="startStage"
                  drop="down"
                  value={newStation.startStage}
                  onChange={handleInputChange}
                >
                  {stageOptions}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="setup.completedStage">
                <Form.Label>Completed Stage:</Form.Label>
                <Form.Control
                  as="select"
                  name="completedStage"
                  drop="down"
                  value={newStation.completedStage}
                  onChange={handleInputChange}
                >
                  {stageOptions}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="setup.scanningActionId">
                <Form.Label>Complete Action:</Form.Label>
                <Form.Control
                  as="select"
                  name="scanningActionId"
                  value={newStation.scanningActionId}
                  onChange={handleInputChange}
                  required
                >
                  {actionOptions}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="setup.scanningActionId">
                <Form.Label>Downgrade Allocation</Form.Label>
                <Form.Control
                  as="select"
                  name="productAllocationId"
                  value={newStation.productAllocationId}
                  onChange={handleInputChange}
                >
                  <option value={0}>Please Select Allocate Items</option>
                  {productsAlocation.length > 0 &&
                    productsAlocation.map(item => (
                      <option key={item.id} value={item.id}>
                        Allocate {item.product} ({item.description})
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="setup.scanNextToComplete">
                <Form.Check
                  inline
                  label="Scan Next Item to Complete"
                  name="scanNextToComplete"
                  type="checkbox"
                  id={`checkboxScanNext`}
                  checked={newStation.scanNextToComplete}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="setup.machineId">
                <Form.Label>Station Machine:</Form.Label>
                <Form.Control
                  as="select"
                  name="machineId"
                  value={newStation.machineId}
                  onChange={handleInputChange}
                >
                  {machineOptions}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="setup.faultAreaId">
                <Form.Label>Fault Area:</Form.Label>
                <Form.Control
                  as="select"
                  name="faultAreaId"
                  value={newStation.faultAreaId}
                  onChange={handleInputChange}
                >
                  {faultAreaOptions}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3">
                <Button variant="primary" type="submit">
                  Save
                </Button>{' '}
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowModalSetting(false);
                  }}
                >
                  Close
                </Button>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </footer>
      <GlobalHotKeys
        keyMap={keyMap}
        handlers={keyMapHandlers}
        allowChanges={true}
      />
    </>
  );
}
