import { React, useState, useEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { swalToast, swalToastError } from 'helpers/component-utils';

export default function GlazingForwardManuAlert(props) {
  const btnRef = useRef(null);
  useEffect(() => {
    if (
      props.show &&
      props.queue[props.queue.length - 1] === 'showModalGFMA' &&
      btnRef
    ) {
      setTimeout(() => {
        btnRef?.current?.focus();
      }, 200);
    }
  }, [props.show, props.queue]);

  let lastKeypressTime = 0;
  const handleKeyUp = async e => {
    e.stopPropagation();
    e.preventDefault();
    let currentTime = new Date().getTime();
    console.log(
      'Glazing Forward Manufacture Enter event time: ' +
        (currentTime - lastKeypressTime)
    );

    if (currentTime - lastKeypressTime < 80) {
      swalToastError(
        'Please confirm Glazing Forward Manufacture before continue scanning'
      );
    } else {
      props.setShowModalGFMA(false);
      const newQueue = props.queue.filter(item => item != 'showModalGFMA');
      await props.updateQueue(newQueue);
    }
    lastKeypressTime = new Date().getTime();
  };

  useEffect(async () => {
    await props.PopDataOfQueue('showModalGFMA', props.setShowModalGFMA);
  }, [props.queue]);

  return (
    <Modal
      show={
        props.show && props.queue[props.queue.length - 1] === 'showModalGFMA'
      }
      dialogClassName="modal-60w"
      size="xl"
      animation={false}
    >
      <Modal.Header style={{ backgroundColor: '#B22222' }}>
        <Modal.Title style={{ textAlign: 'center', margin: 'auto' }}>
          <h2 style={{ color: 'white' }}>ATTENTION</h2>
        </Modal.Title>
        <span className="blink">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="4x"
            color="white"
          />
        </span>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: '50px', textAlign: 'center' }}>
          <h3>
            THIS JOB IS <b>FORWARD MANUFACTURE</b>
          </h3>
          <br></br>
          <h3>
            PLEASE RAISE A GLASS IPF <b>NOT</b> AN ODR
          </h3>
          <br></br>
          <h3>
            HOLD FRAME IN GLAZING AREA - <b>DO NOT</b> SEND TO DESPATCH
          </h3>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        {/* <Button
            id="btnOK"
            className="btn btn-primary"
            autoFocus
            onClick={() => props.setShowModalGFMA(false)}
          >
            Confirm
          </Button> */}
        <input
          type="button"
          className="btn btn-primary"
          value={'Confirm'}
          //onKeyUp={e => handleKeyUp(e)}
          onClick={e => handleKeyUp(e)}
          ref={btnRef}
        />
      </Modal.Footer>
    </Modal>
  );
}
