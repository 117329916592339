import {
  swalToast,
  swalToastError,
  swalToastSuccess
} from 'helpers/component-utils';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Carousel, Image, Modal } from 'react-bootstrap';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import '../../assets/scss/user.scss';
import {
  getUserScanNoFromLastQc,
  updateTrackingQc
} from 'services/stationQcService';
import { addQcLog } from 'services/qualitycheckService';
import dayjs from 'dayjs';
import { sendEmail } from 'services/mailService';

export default function QualityCheck(props) {
  const station = props.station;
  const loginData = props.loginData;
  const barcode = props.barcode;
  const checks = props.checks;
  const userTrackingId = props.userTrackingId;
  const manual = props.manual;

  const btnRef = useRef(null);

  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepObject, setCurrentStepObject] = useState({});
  const [currentCheckModal, setCurrentCheckModal] = useState({
    index: 0,
    check: {
      id: 0,
      stationId: 0,
      checkType: 0,
      checkTypeName: '',
      measurable: 0,
      checkEvery: 0,
      createdBy: 0,
      created: '',
      updatedBy: 0,
      updated: '',
      steps: []
    },
    show: true
  });
  const [activeCheckModals, setActiveCheckModals] = useState([]);

  useEffect(async () => {
    if (manual) {
      if (checks.length > 0) {
        checks.sort((a, b) => a.id - b.id);
        let modalList = [];
        checks.map((c, i) => {
          let modal = { index: i + 1, check: c, show: true };
          modalList.push(modal);
        });
        setActiveCheckModals(modalList);
        await props.updateQueue('isShowQCCheck');
        setCurrentCheckModal({ ...modalList[0] });
        setCurrentStepObject(modalList[0].check.steps[0]);
      }
    }
  }, [manual]);

  useEffect(() => {
    if (barcode) {
      processArray(checks)
        .then(async result => {
          if (result.length > 0) {
            result.sort((a, b) => a.id - b.id);
            let modalList = [];
            result.map((c, i) => {
              let modal = { index: i + 1, check: c, show: true };
              modalList.push(modal);
            });
            setActiveCheckModals(modalList);
            await props.updateQueue('isShowQCCheck');
            setCurrentCheckModal({ ...modalList[0] });
            setCurrentStepObject(modalList[0].check.steps[0]);
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [barcode]);

  const processArray = async checks => {
    let result = [];
    if (checks.length > 0) {
      await Promise.all(
        checks.map(async item => {
          const res = await getUserScanNoFromLastQc(loginData.userId, item.id);
          if (res && (res.scanNo === 0 || res.scanNo >= item.checkEvery + 1)) {
            result.push(item);
          }
        })
      );
    }

    return result;
  };

  let lastKeypressTime = 0;
  const handleKeyUp = e => {
    e.stopPropagation();
    e.preventDefault();
    let currentTime = new Date().getTime();

    if (currentTime - lastKeypressTime < 80) {
      swalToastError('Please complete Quality Check before continue scanning');
    } else {
      handleConfirm(currentCheckModal.index);
    }
    lastKeypressTime = new Date().getTime();
  };

  const handleConfirm = modalIndex => {
    const qcLog = {
      stationId: station.id,
      qcId: currentCheckModal.check.id,
      qcStepId: currentStepObject.id,
      checkSuccess: true,
      checkedBy: loginData.userId
    };
    handleCheckingQc(qcLog, modalIndex, 'Confirmed');
  };

  const handleMachineIssue = modalIndex => {
    const qcLog = {
      stationId: station.id,
      qcId: currentCheckModal.check.id,
      qcStepId: currentStepObject.id,
      checkSuccess: false,
      machineIssue: true,
      checkedBy: loginData.userId
    };

    // send email
    const email = {
      subject: 'QC - Machine Issue Report',
      app: 'qc-machine-issue-report',
      // to: 'dawid.hajduk@emplas.co.uk, james.turner@emplas.co.uk, andy.lindsay-bell@emplas.co.uk, david.willers@emplas.co.uk, dan.mason@emplas.co.uk, jack.abbott@emplas.co.uk, productiongroup@emplas.co.uk',
      // cc: 'trung.tran@emplas.co.uk, thien.pham@emplas.co.uk, quang.dinh@emplas.co.uk',
      body: `QC - Machine issue reported at ${station.stationName} station by ${
        loginData.userName
      } - ${dayjs().format('DD/MM/YYYY HH:mm')}`
    };

    sendEmail(email).then(res => {
      console.log(res);
    });

    handleCheckingQc(qcLog, modalIndex, 'Machine Issue Reported');
  };

  const handleProfileIssue = modalIndex => {
    const qcLog = {
      stationId: station.id,
      qcId: currentCheckModal.check.id,
      qcStepId: currentStepObject.id,
      checkSuccess: false,
      profileIssue: true,
      checkedBy: loginData.userId
    };

    // send email
    const email = {
      subject: 'QC - Profile Issue Report',
      app: 'qc-profile-issue-report',
      // to: 'dawid.hajduk@emplas.co.uk, kevin.mander@emplas.co.uk, arturas.marcionis@emplas.co.uk, productiongroup@emplas.co.uk',
      // cc: 'trung.tran@emplas.co.uk, thien.pham@emplas.co.uk, quang.dinh@emplas.co.uk',
      body: `QC - Profile issue reported at ${station.stationName} station by ${
        loginData.userName
      } - ${dayjs().format('DD/MM/YYYY HH:mm')}`
    };

    sendEmail(email).then(res => {
      console.log(res);
    });

    handleCheckingQc(qcLog, modalIndex, 'Profile Issue Reported');
  };

  const handleCheckingQc = (logItem, modalIndex, message) => {
    if (currentStep < currentCheckModal.check.steps.length) {
      addQcLog(logItem)
        .then(res => {
          if (res?.success) {
            let nextStep = currentStep;
            nextStep++;
            if (nextStep >= currentCheckModal.check.steps.length) {
              swalToastSuccess(
                `${currentCheckModal.check.checkTypeName} Check Completed`
              );
              // do update tracking qc if barcode scanned
              if (barcode) {
                updateTrackingQc(
                  userTrackingId,
                  currentCheckModal.check.id
                ).catch(error => {
                  console.log(error);
                  swalToastError('Updated Tracking QC Failed');
                });
              }

              var nextCheckModal = activeCheckModals.find(
                x => x.index === Number(currentCheckModal.index + 1)
              );
              if (nextCheckModal) {
                setCurrentCheckModal(nextCheckModal);
                setCurrentStepObject(nextCheckModal.check.steps[0]);
                setCurrentStep(0);
              } else {
                setCurrentStep(0);
                swalToastSuccess('All QC Completed');
                setActiveCheckModals(prevModals => {
                  return prevModals.map(modal => {
                    return { ...modal, show: false };
                  });
                });
                props.onAllQcCompleted();
              }
            } else {
              swalToastSuccess(message);
              setCurrentStepObject(currentCheckModal.check.steps[nextStep]);
              setCurrentStep(nextStep);
            }
          } else {
            swalToastError('Failed to log check');
          }
        })
        .catch(err => {
          console.log(err);
          swalToastError('Error. Something Went Wrong');
        });
    } else {
      setActiveCheckModals(prevModals => {
        return prevModals.map(modal => {
          if (modal.index === modalIndex) {
            return { ...modal, show: false };
          }
          return modal;
        });
      });
    }
  };

  useEffect(async () => {
    await props.PopDataOfQueue('isShowQCCheck', props.setIsShowQCCheck);
  }, [props.queue]);

  useEffect(() => {
    if (
      props.isShowQCCheck &&
      props.queue[props.queue.length - 1] === 'isShowQCCheck' &&
      btnRef
    ) {
      setTimeout(() => {
        btnRef?.current?.focus();
      }, 200);
    }
  }, [props.isShowQCCheck, props.queue]);

  return (
    <div className="quality-check-popup">
      {activeCheckModals.map(modal => (
        <Modal
          key={modal.index}
          show={
            modal.show &&
            props.isShowQCCheck &&
            props.queue[props.queue.length - 1] === 'isShowQCCheck'
          }
          size="lg"
          backdrop="static"
          contentClassName="border"
        >
          <Modal.Header
            className="bg-light px-card border-bottom-0"
            closeButton={null}
          >
            <Modal.Title as="h4">
              QC - {currentCheckModal.check.checkTypeName} Checking<br></br>
              <small>
                Step: {currentStep + 1}/{currentCheckModal.check.steps.length}
              </small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '80vh' }}>
            <div>
              <div className="row">
                <div className="col-sm-12">{currentStepObject.instruction}</div>
              </div>
              <div className="row">
                <div
                  className="col-sm-12 d-flex"
                  style={{ justifyContent: 'center' }}
                >
                  {currentStepObject.images &&
                  currentStepObject.images.length > 0 ? (
                    <Carousel interval={10000} style={{ height: '55vh' }}>
                      {currentStepObject.images.map((img, index) => (
                        <Carousel.Item key={index}>
                          <Image
                            key={`img${img.id}`}
                            src={`${
                              process.env.REACT_APP_FILE_API_HOST
                            }/files/download/${
                              process.env.REACT_APP_BUCKET_NAME
                            }?key=${img.path.replace('s3/eva-files/', '')}`}
                            alt=""
                            fluid
                            //className="check-img"
                            style={{
                              padding: '5px',
                              cursor: 'pointer',
                              height: 'auto',
                              maxHeight: '55vh'
                            }}
                          ></Image>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-primary"
              onClick={e => handleKeyUp(e)}
              ref={btnRef}
            >
              Confirm
            </Button>
            <Button
              className="btn btn-warning"
              onClick={() => handleMachineIssue(modal.index)}
            >
              Report Machine Issue
            </Button>
            <Button
              className="btn btn-danger"
              onClick={() => handleProfileIssue(modal.index)}
            >
              Report Profile Issue
            </Button>
          </Modal.Footer>
        </Modal>
      ))}
    </div>
  );
}
